import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sluggify } from '../../utils/Sluggify';
import { MembersData, MemberTypes } from './data';
import BG_IMG from '../../assets/images/bio/bio-bg.jpg';
import BGMOBILE_IMG from '../../assets/images/bio/bg-bio-mobile.jpg';
import HERO_IMG from '../../assets/images/bio/bio-showcase.png';
import OtherMembers from './OtherMembers';
import Initiatives from '../Home/Initiatives/Initiatives';
import Partners from '../Home/Partners/Partners';
import { motion } from 'framer-motion';
import { fadeInFadeOut } from '../../components/FramerMotion/Fade';

interface ShowcaseImgProps {
  img: string;
}

const ShowcaseImg = styled.div<ShowcaseImgProps>`
  @media (min-width: 768px) {
    background-position: top;
    background-repeat: no-repeat;
    background-image: url('${(props) => props.img}');
    background-attachment: fixed;
    background-size: contain;
  }
`;

const Banner = styled.div`
  background-image: url('${BGMOBILE_IMG}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    background-image: url('${BG_IMG}');
    background-attachment: fixed;
  }
`;

const Bio = (props: any) => {
  const [currentMember, setCurrentMember] = useState<MemberTypes | null>(null);

  useEffect(() => {
    const currentSlug = props.match.params.slug;

    MembersData.map((item) => {
      if (sluggify(item.name) === currentSlug) {
        setCurrentMember(item);
      }
      return null;
    });
  }, [props.match]);

  if (!currentMember)
    return (
      <>
        <div className="min-h-screen fixed top-0 left-0 bg-black z-50" />
      </>
    );

  return (
    <motion.div {...fadeInFadeOut}>
      <ShowcaseImg
        img={currentMember.showcaseImg}
        className="flex flex-col justify-center items-center relative overflow-hidden"
      >
        <img src={currentMember.showcaseImg} alt="banner" className="opacity-0 w-full" />
        <img
          src={currentMember.showcaseImg}
          alt="banner"
          className="opacity-100 w-full object-cover h-full absolute top-0 left-0"
        />
        <div className="mt-auto mb-10 mmx-auto text-center text-white">
          <h2 className="text-white uppercase font-futura text-xl md:text-3xl from-left">
            {currentMember.name}
          </h2>
          <span className="font-futura from-right">BIOGRAPHY</span>
        </div>
      </ShowcaseImg>
      <div className="py-10 md:py-16  bg-gray-2 text-white">
        <div className="w-5/6 md:w-3/4 mx-auto">
          <p dangerouslySetInnerHTML={{ __html: currentMember.info }} className="text-center"></p>
        </div>
      </div>
      <Banner className="pt-10 overflow-hidden">
        <img src={HERO_IMG} alt="banner" className="w-5/6 md:w-1/4 mx-auto from-bottom" />
      </Banner>
      <OtherMembers />
      <Initiatives />
      <Partners />
    </motion.div>
  );
};

export default Bio;
