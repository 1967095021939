import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import styled from 'styled-components';

const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12312313;
`;

export default function Navigation() {
  return (
    <Container className="w-full absolute top-0 left-0 text-center z-50 from-top p-2">
      <DesktopNavigation />
      <MobileNavigation />
    </Container>
  );
}
