import GZSlider from '../../../components/GZSlider/GZSlider';
import { members } from './data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { sluggify } from '../../../utils/Sluggify';
import { RoutePattern } from '../../../routes/RoutePattern';

const Member = styled.a``;

const Members = () => {
  return (
    <div id={RoutePattern.Members} className="relative pt-10 pb-24 px-5 md:pt-16">
      <h2 className="text-white text-xl text-center font-futura from-top">MEMBERS</h2>
      <div className="mt-10 flex-wrap justify-center items-center w-3/4 mx-auto hidden md:flex">
        {members.map((item) => (
          <Member
            key={item.id}
            href={`/bio/${sluggify(item.name)}`}
            className="flex flex-col w-4/12 p-5 from-left md:from-top"
          >
            {item.profileImg ? (
              <div className="max-h-72 overflow-hidden">
                <img
                  src={item.profileImg}
                  alt="member"
                  className="object-cover object-top transform hover:scale-125 transition-all duration-700"
                />
              </div>
            ) : (
              <div className="bg-gray-1 flex justify-center items-center py-24">
                <FontAwesomeIcon icon={faUser} className="text-6xl text-white" />
              </div>
            )}
            <div className="p-5 bg-white text-center ">
              <span className="text-black block font-futura">{item.name}</span>
              {item.title && <span>{item.title}</span>}
            </div>
          </Member>
        ))}
      </div>
      <div className="mt-5 block md:hidden">
        <GZSlider>
          {members.map((item) => (
            <Member key={item.id} href={`/bio/${sluggify(item.name)}`} className="flex flex-col">
              {item.profileImg ? (
                <div className="h-96 overflow-hidden">
                  <img
                    src={item.profileImg}
                    alt="member"
                    className="object-cover h-full object-top transform hover:scale-125 transition-all duration-700"
                  />
                </div>
              ) : (
                <div className="bg-gray-1 flex justify-center items-center h-96">
                  <FontAwesomeIcon icon={faUser} className="text-6xl text-white" />
                </div>
              )}
              <div className="p-5 bg-white text-center ">
                <span className="text-black block font-futura">{item.name}</span>
                {item.title && <span>{item.title}</span>}
              </div>
            </Member>
          ))}
        </GZSlider>
      </div>
    </div>
  );
};

export default Members;
