import MEMBER1_IMG from '../../../assets/images/member-1.jpg';
import MEMBER2_IMG from '../../../assets/images/member-2.png';
import MEMBER3_IMG from '../../../assets/images/member-3.png';
import MEMBER4_IMG from '../../../assets/images/member-4.jpg';
import MEMBER6_IMG from '../../../assets/images/member-5.jpg';

export const members = [
  {
    id: 1,
    profileImg: MEMBER1_IMG,
    name: 'Victoria Oladipo',
  },
  {
    id: 2,
    profileImg: MEMBER2_IMG,
    name: 'Dr. Kristine Oladipo',
  },
  {
    id: 3,
    profileImg: MEMBER3_IMG,
    name: 'Victor Oladipo',
  },
  {
    id: 4,
    profileImg: null,
    name: 'Cherelle Silue',
    title: 'Director of Housing Counseling Programs Financial Capability Coach',
  },
  {
    id: 5,
    profileImg: MEMBER4_IMG,
    name: 'Shameeka Price',
    title: ' Real Estate Broker and Developer',
  },
  {
    id: 6,
    profileImg: MEMBER6_IMG,
    name: 'Tonya Jackson RN, BSN, MSHA,CMDN',
    title: 'Board Member',
  },
];
