import { motion } from 'framer-motion';
import { fadeInFadeOut } from '../../components/FramerMotion/Fade';
import About from './About/About';
import Hero from './Hero/Hero';
import Initiatives from './Initiatives/Initiatives';
import Members from './Members/Members';
import Mission from './Mission/Mission';
import Partners from './Partners/Partners';

const Home = () => {
  return (
    <motion.div {...fadeInFadeOut} id="home" className="w-full">
      <Hero />
      <Mission />
      <About />
      <Members />
      <Initiatives />
      <Partners />
    </motion.div>
  );
};

export default Home;
