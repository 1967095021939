import BG_IMG from '../../../assets/images/bg-mission.jpg';
import BGMOBILE_IMG from '../../../assets/images/bg-mission-mobile.jpg';
import HERO_IMG from '../../../assets/images/victor-1.png';
import styled from 'styled-components';
import { RoutePattern } from '../../../routes/RoutePattern';

const Banner = styled.div`
  padding-top: 1.2rem;
  background-image: url('${BGMOBILE_IMG}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    background-image: url('${BG_IMG}');
    background-attachment: fixed;
  }
`;

const Mission = () => {
  return (
    <div id={RoutePattern.Mission} className="relative w-full z-10 bg-gray-1 md:pb-10">
      <Banner>
        <img src={HERO_IMG} alt="banner" className="w-full md:w-1/2 mx-auto from-bottom" />
      </Banner>
      <div className="px-5">
        <div className="bg-white w-11/12 p-5 -mt-10 absolute  left-1/2 transform -translate-x-1/2 md:p-16 md:w-3/4">
          <h2 className="text-purple-800 text-xl text-center font-futura from-top">MISSION</h2>
          <p className="mt-5 text-center from-right">
            The Oladipo Foundation aspires to empower the next generation of young women to strive
            without limits by fostering a culture of competence, creativity, capacity, and community
            through opportunity and education- "The Oladipo Way"
          </p>
        </div>
        <div className="bg-white w-11/12 p-5 opacity-0 md:p-16">
          <h2 className="text-purple-800 text-xl text-center font-futura">MISSION</h2>
          <p className="mt-5 text-center">
            The Oladipo Foundation aspires to empower the next generation of young women to strive
            without limits by fostering a culture of competence, creativity, capacity, and community
            through opportunity and education- "The Oladipo Way"
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
