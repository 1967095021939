import styled from 'styled-components';
import HERO_IMG from '../../../assets/images/victor-2.png';
import BG_IMG from '../../../assets/images/bg-about.jpg';
import { aboutLogo } from './data';
import { RoutePattern } from '../../../routes/RoutePattern';

const Banner = styled.div`
  padding-top: 5rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url('${BG_IMG}');
  @media (min-width: 768px) {
    padding-top: 1.2rem;
    background-attachment: fixed;
  }
`;

const About = () => {
  return (
    <div id={RoutePattern.AboutUs} className="w-full overflow-hidden relative bg-gray-2">
      <Banner>
        <img src={BG_IMG} alt="bg" className="opacity-0" />
      </Banner>
      <div className="relative overflow-visible px-5 py-10 md:py-16">
        <img src={HERO_IMG} alt="hero" className="w-1/2 md:w-1/4 absolute bottom-0 left-0 z-0" />
        <div className="w-3/4 mx-auto relative z-10">
          <h2 className="text-white text-xl text-center font-futura from-top">ABOUT US</h2>
          <p className="mt-5 text-white text-center from-right">
            The Oladipo Family Foundation is built on the four pillars of Confidence, Creativity,
            Capacity and Community. We strive to empower women of color to limitless possibilities
            using The Oladipo Way. Through fund development like A Guy for Gals Gala, Stronger
            Together and Uplift Her initiatives, we help to develop a meaningful and enjoyable
            journey that creates exposure. At The Oladipo Family Foundation we are not one and done!
          </p>
          <div className="flex flex-wrap justify-center items-center mt-5 md:mt-10">
            {aboutLogo.map((item) => (
              <div key={item.name} className="p-5 text-center w-1/2 md:w-1/4 from-right">
                <img src={item.img} alt={item.name} className="w-16 h-16 mx-auto" />
                <span className="mt-3 block text-white capitalize from-bottom">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
