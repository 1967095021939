import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'react-responsive-carousel';

const GZSlider = (props: any) => {
  const renderNextArrow = (onClickHandler: any, hasNext: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute right-5 bottom-5 z-50 outline-none focus:outline-none from-right"
    >
      {hasNext && <FontAwesomeIcon icon={faAngleRight} size="2x" />}
    </button>
  );

  const renderPrevArrow = (onClickHandler: any, hasPrev: boolean, label: string) => (
    <button
      onClick={onClickHandler}
      className="text-white absolute left-5 bottom-5 z-50 outline-none focus:outline-none from-left"
    >
      {hasPrev && <FontAwesomeIcon icon={faAngleLeft} size="2x" />}
    </button>
  );

  return (
    <>
      <Carousel
        swipeable={false}
        emulateTouch
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        renderArrowNext={renderNextArrow}
        renderArrowPrev={renderPrevArrow}
        className="block md:hidden"
      >
        {props.children}
      </Carousel>
    </>
  );
};

export default GZSlider;
