import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

// temporary types (need to be debugged)
export interface SocialDataTypes {
  icon: string;
  to: string;
}

export const data = [
  {
    icon: faFacebookF,
    to: 'https://www.facebook.com/JourneyGzMusic',
  },
  {
    icon: faTwitter,
    to: 'https://twitter.com/journey_gz',
  },
  {
    icon: faYoutube,
    to: 'https://www.youtube.com/channel/UCZpxnTQtCSwcQ3D5WrjFqYg',
  },
  {
    icon: faInstagram,
    to: 'https://www.instagram.com/journey.gz/',
  },
  {
    icon: faApple,
    to: 'https://music.apple.com/us/artist/journey-gz/1356848477',
  },
  {
    icon: faSpotify,
    to: 'https://open.spotify.com/artist/53WX2PlTCRBDSlePgc6v5C?si=xOWT-AzGTqS_-fVsUumrUg',
  },
];
