import { initiativeBtns } from './data';
import styled from 'styled-components';
import BG_IMG from '../../../assets/images/bg-initiatives.jpg';
import BGMOBILE_IMG from '../../../assets/images/bg-initiatives-mobile.jpg';
import { RoutePattern } from '../../../routes/RoutePattern';

const Container = styled.div`
  background-image: url('${BGMOBILE_IMG}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    background-image: url('${BG_IMG}');
    background-attachment: fixed;
  }
`;

const spanStyle = 'text-purple-500 font-futura';

const Initiatives = () => {
  return (
    <Container id={RoutePattern.Initiatives} className="relative py-10 px-5 bg-gray-2 md:py-16">
      <div className="w-3/4 mx-auto">
        <h2 className="uppercase text-white text-2xl text-center from-bottom">
          EM<span className={spanStyle}>POWER</span>ING WOMEN <br />{' '}
          <span className={spanStyle}>TO</span>
          GET
          <span className={spanStyle}>HER</span>
        </h2>
        <h4 className="mt-3 font-futura text-white uppercase text-center from-right">
          OTHER INITIATIVES
        </h4>
        <div className="flex justify-center items-center flex-wrap mt-5">
          {initiativeBtns.map((item) => (
            <div
              key={item.name}
              className="w-full md:px-2 md:w-1/2 text-white mt-3 from-left md:from-top"
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="py-5 block w-full text-center bg-gray-1 hover:bg-gray-600 transition-all "
              >
                {item.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Initiatives;
