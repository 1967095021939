import { motion } from 'framer-motion';
import { useState } from 'react';
import { hover } from '../FramerMotion/Framer';
import { SlideUpSlideDown } from '../FramerMotion/Slide';
import { menuItems } from './data';
import { RoutePattern } from '../../routes/RoutePattern';
import { Link, Route } from 'react-router-dom';

export default function DesktopNavigation() {
  const [crntHoveredItem, setCrntHoveredItem] = useState<number>(-1);

  const navItems = menuItems.map((item, idx) => (
    <li
      key={idx}
      onMouseOver={() => setCrntHoveredItem(idx)}
      onMouseOut={() => setCrntHoveredItem(-1)}
      className="text-white block text-xl cursor-pointer"
    >
      <a
        href={item.to === '/' ? '/' : '/#' + item.to}
        data-scroll-to={item.to === '/' ? 'home' : '#' + item.to}
        className="px-5 relative"
      >
        {item.name}
        <motion.span
          variants={hover}
          animate={crntHoveredItem === idx ? 'visible' : 'hidden'}
          className="h-1 w-3/4 absolute -bottom-2 left-1/2 transform -translate-x-1/2 bg-white"
        />
      </a>
    </li>
  ));

  return (
    <motion.ul
      {...SlideUpSlideDown}
      className="flex-row hidden w-full justify-end p-10 ml-auto items-center md:flex"
    >
      {navItems}
      <a
        href={RoutePattern.ContactUs}
        className="px-5 relative py-2 bg-purple-500 text-white text-xl"
      >
        Join Us
      </a>
    </motion.ul>
  );
}
