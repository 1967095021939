import AAM_IMG from '../../../assets/images/AAM.png';
import HS_IMG from '../../../assets/images/HS.png';
import HPU_IMG from '../../../assets/images/HPU.png';

const Partners = () => {
  return (
    <div className="bg-white py-10 px-5">
      <div className="w-3/4 mx-auto">
        <h2 className="font-futura text-black text-center from-bottom">PARTNERS WE SUPPORT</h2>
        <div className="mt-10 flex flex-col justify-between items-center md:flex-row">
          <div className="from-bottom">
            <p className="text-center text-black font-futura whitespace-nowrap md:mr-10">
              Domestic Violence <br />
              (Safe Haven &amp; <br />
              Sherriff Department )
            </p>
          </div>
          <div className="mt-10 md:mt-0 w-full from-bottom">
            <img src={AAM_IMG} alt="" className="mx-auto" />
          </div>
          <div className="mt-10 md:mt-0 w-full from-bottom">
            <img src={HS_IMG} alt="" className="mx-auto md:w-2/5" />
          </div>
          <div className="mt-10 md:mt-0 w-full from-bottom">
            <img src={HPU_IMG} alt="" className="mx-auto md:w-1/2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
