import VICTORIA_IMG from '../../assets/images/bio/victoria-bio.png';
import VICTOR_IMG from '../../assets/images/bio/victor-bio.png';
import KRISTINE_IMG from '../../assets/images/bio/kristine-bio.png';
import SHAMEEKA_IMG from '../../assets/images/bio/shameeka-bio.jpg';
import TOBEFILLEDIN from '../../assets/images/bio/bio-avatar.png';
import TONYA_IMG from '../../assets/images/bio/tonya-bio.jpg';

export interface MemberTypes {
  id: number;
  name: string;
  showcaseImg: string;
  info: string;
}

export const MembersData: MemberTypes[] = [
  {
    id: 1,
    name: 'Victoria Oladipo',
    showcaseImg: VICTORIA_IMG,
    info: `Was born and raised in Prince George's County, Maryland. She
graduated with a Bachelor’s Degree in both Political Science and Communications
from the University of Maryland, College Park. After obtaining various
internships in the sports and entertainment business, she then went on to receive
her Master’s Degree in Sports Industry Management from Georgetown
University.
<br/>
<br/>
After working at different agencies she realized her passion for
assisting athletes with their foundations and nonprofits. Later on Victoria joined
her brother’s, Victor Oladipo, team where she assisted in decision-making on his
behalf. However, with the desire to help others, Victoria advocated for her brother,
Victor Oladipo, to start his own foundation in hopes of making a difference and
impacting the lives of the underprivileged and underserved.
`,
  },
  {
    id: 2,
    name: 'Dr. Kristine Oladipo',
    showcaseImg: KRISTINE_IMG,
    info: `Was born Prince George’s County, Maryland. She is the oldest of
four with 2 sisters and 1 brother. She received her Bachelors in Science from Temple University where she majored in Biology. After doing research in gene therapy in Philadelphia, Pennsylvania she returned home where she completed her Master’s Degree in Biology at Towson University.
<br/>
<br/>
Soon after, she began her quest towards obtaining a Doctorate in Dental Surgery, DDS, from the University of Maryland, Baltimore. She started her dental school career with the goal of attending pediatric dental residency to change the negative perception that children often associate with going to the dentist. Through perseverance and dedication, she has made her goals a reality and attended pediatric dental residency on the Eastern Shore. While at the University of Maryland School of Dentistry, she received The Outstanding Dean's Award for community service in the city of Baltimore. 
<br/>
<br/>
Eager educate the youth, she volunteered with a number of dental outreach programs
including the Deamonte Driver Mobile Dental Unit where she taught underserved children the importance of proper dental care procedures such as brushing teeth efficiently and flossing adequately. Presently, Dr. Oladipo continues volunteering her spare time with her sorority Alpha Kappa Alpha Inc. & YBAD; Young Black American Doctor, teaching the importance of dental hygiene as she works toward opening her own pediatric dental practice.
`,
  },
  {
    id: 3,
    name: 'Victor Oladipo',
    showcaseImg: VICTOR_IMG,
    info: `was born and raised in Princes Georges County, Maryland. He has a twin
sister and is the only boy out of four siblings. Oladipo and his twin sister, Victoria, have
always shared a special bond. They were inseparable and did almost everything together up until high school. While Oladipo attended DeMatha Catholic High School, Victoria attended their sister school Elizabeth Ann Seton.
<br/>
<br/>
After graduating from DeMatha, Oladipo received a full scholarship to Indiana University to play collegiate basketball. In three years he obtained a Bachelor’s Degree in Kinesiology. In 2013 Oladipo decided to enter the NBA draft where he was selected as the number two overall pick to the Orlando Magic.
<br/>
<br/>
Oladipo has always had a passion for giving back to the less fortunate and leaving a
lasting impact on the community. While in Orlando, Oladipo started a non-profit
organization called Dunk Cancer that “focused on funding cancer research and initiatives.
`,
  },
  {
    id: 4,
    name: 'Cherelle Silue',
    showcaseImg: TOBEFILLEDIN,
    info: `
    Financial coaching is conducted in a one-to-one setting and strives to help clients identify their own path toward financial success. Coaching centers on building a trusting partnership with the client by “designing an alliance” that is future focused. Financial coaching is a client driven process that is focused on asking “asking powerful question” and conducting various exercises that help clients identify the outcomes they truly desire and develop a clear plan of action to achieve them.
    <br/>
    <br/>
Financial counseling helps clients to improve their financial security and increase their net worth. Counselors work one-on-one with individuals and families to assess their current financial condition, determine goals and develop a plan to meet those goals. A financial counselor conducts financial analysis and calculation, educates the client on potential options, and makes recommendations on strategies to overcome financial barriers and/or achieve the client’s desired outcomes. Clients play an active role in defining what they want to achieve, and the counselor helps foster the development of sound financial decision-making.`,
  },
  {
    id: 5,
    name: 'Shameeka Price',
    showcaseImg: SHAMEEKA_IMG,
    info: `Shameeka Price is a successful Real Estate Broker and Developer. Under her leadership and through her vision, she has led, guided and built one of the region’s most prominent Multi Million Dollar Real Estate firms, “Capital Structures Real Estate.” For nearly two decades, she has progressed through the Real Estate industry as a licensed agent, real estate broker, licensed home improvement general contractor and a certified homeownership trainer. Shameeka specializes in all facets of real estate giving her a unique advantage in the competitive market.
<br/> 
<br/> 
Shameeka, is an engaging broker known for her extensive market knowledge and her unmatched dedication to all of her clients; Shameekas success is based almost exclusively on positive referrals. She earns the respect of her clients by working tirelessly on their behalf and by always offering them candid advice.
<br/> 
<br/> 
Shameeka, is also a recognized and respected community leader serving as the current President of the Prince George’s County Association of Realtors (PGCAR) and Executive Board Member of the Prince George’s Chamber of Commerce to name a few. She founded the “Real Women Invest in Real Estate” and “Financial Education and Empowerment, Inc.” two non-profit corporations. As a mentor, Shameeka continues to advise women on the East Coast helping them to transform themselves into thriving women of power.

`,
  },
  {
    id: 6,
    name: 'Tonya Jackson RN, BSN, MSHA,CMDN',
    showcaseImg: TONYA_IMG,
    info: 'Coming soon...',
  },
];
