import { RoutePattern } from '../../routes/RoutePattern';
import { MenuItemsTypes } from './types';

export const menuItems: MenuItemsTypes[] = [
  {
    name: 'Home',
    to: RoutePattern.Home,
  },
  {
    name: 'About Us',
    to: RoutePattern.AboutUs,
  },
  {
    name: 'Members',
    to: RoutePattern.Members,
  },
  {
    name: 'Initiatives',
    to: RoutePattern.Initiatives,
  },
];
