import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GZSlider from '../../components/GZSlider/GZSlider';
import { sluggify } from '../../utils/Sluggify';
import { members } from '../Home/Members/data';

const Member = styled.a``;

const OtherMembers = () => {
  return (
    <div className="relative pt-10 pb-24 px-5 md:pt-16">
      <h2 className="text-white text-xl text-center font-futura from-top">OTHER MEMBERS</h2>
      <div className="mt-10 flex-wrap justify-center items-center w-3/4 mx-auto hidden md:flex">
        {members.map((item) => (
          <Member
            key={item.id}
            href={`/bio/${sluggify(item.name)}`}
            className="flex flex-col w-4/12 p-5"
          >
            {item.profileImg ? (
              <img
                src={item.profileImg}
                alt="member"
                className="from-left max-h-72 object-cover object-top md:from-top"
              />
            ) : (
              <div className="bg-gray-1 flex justify-center items-center py-24 from-left">
                <FontAwesomeIcon icon={faUser} className="text-6xl text-white" />
              </div>
            )}
            <div className="p-5 bg-white text-center ">
              <span className="text-black block font-futura from-right md:from-top">
                {item.name}
              </span>
              {item.title && <span className="from-left">{item.title}</span>}
            </div>
          </Member>
        ))}
      </div>
      <div className="mt-5 block md:hidden">
        <GZSlider>
          {members.map((item) => (
            <Member key={item.id} href={`/bio/${sluggify(item.name)}`} className="flex flex-col">
              {item.profileImg ? (
                <img src={item.profileImg} alt="member" className="h-96 object-cover object-top" />
              ) : (
                <div className="bg-gray-1 flex justify-center items-center h-96">
                  <FontAwesomeIcon icon={faUser} className="text-6xl text-white" />
                </div>
              )}
              <span className="p-5 bg-white text-center text-black block font-futura">
                {item.name}
              </span>
            </Member>
          ))}
        </GZSlider>
      </div>
    </div>
  );
};

export default OtherMembers;
