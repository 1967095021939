import { motion } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';
import HERO_IMG from '../../assets/images/bg-desktop.png';
import { fadeInFadeOut } from '../../components/FramerMotion/Fade';

const FormButton = styled.button`
  background: white;
  padding: 0.5rem 1.2rem;
  margin-top: 1.2rem;
`;

const FormInput = styled.input`
  padding: 0.5rem;
`;

const Label = styled.label`
  color: white;
`;

const FormGroup = styled.div`
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Container = styled(motion.div)`
  height: 88vh;
  background-image: url('${HERO_IMG}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Contact = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  return (
    <Container {...fadeInFadeOut} className="flex justify-center items-center relative">
      <div>
        <Form method="POST">
          <FormGroup>
            <FormInput
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="text"
              placeholder="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormGroup>
          <FormButton type="submit">Submit</FormButton>
        </Form>
      </div>
    </Container>
  );
};

export default Contact;
