import { motion } from 'framer-motion';
import { fadeInFadeOut } from '../FramerMotion/Fade';

const Footer = () => {
  return (
    <motion.div
      {...fadeInFadeOut}
      className="flex flex-col justify-center items-center py-5 text-white bg-black mt-auto"
    >
      <p>Oladipo Family Foundation</p>
      <span className="text-md">&copy; 2021</span>
    </motion.div>
  );
};
export default Footer;
